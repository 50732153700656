.body {
  width: 800px;
  min-width: 800px;
  max-width: 800px;
  background: white;
  margin: 0px auto;

  -webkit-animation-name: reset, fade-in;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in;

  -moz-animation-name: reset, fade-in;
  -moz-animation-duration: 1.5s;
  -moz-animation-timing-function: ease-in;

  animation-name: reset, fade-in;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

section {
  border-top: 1px solid #dedede;
  padding-top: 0px;
}

#contactDetails {
  float: right;
}

#contactDetails ul {
  list-style-type: none;
  font-size: 0.9em;
  margin-top: 2px;
}

#contactDetails ul li {
  margin-bottom: 3px;
  color: #444;
}

#contactDetails ul li a,
a[href^="tel"] {
  color: #444;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#contactDetails ul li a:hover {
  color: #cf8a05;
}

section ul {
  font-size: 0.6em;
  line-height: 1.4em;
  margin-bottom: 10px;
  color: #444;
  list-style-position: inside;
}

.headerSection {
  float: left;
}
.name {
  font-size: 2em;
  font-weight: 700;
  font-family: "Rokkitt", Helvetica, Arial, sans-serif;
  margin-bottom: -6px;
}

.title {
  font-size: 1.5em;
  font-family: "Rokkitt", Helvetica, Arial, sans-serif;
}

.clear {
  clear: both;
}

section:last-child {
  padding: 0px;
}

.sectionTitle {
  text-align: center;
  font-family: "Rokkitt", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 1.35em;
  color: #cf8a05;
  padding: 5px 0px;
}

.centered {
  margin: auto;
}

.starttest {
  margin: none;
}

.sectionContent {
  font-family: "Rokkitt", Helvetica, Arial, sans-serif;
  font-size: 1.25em;
  margin-bottom: -2px;
}

.subDetails {
  font-size: 0.7em;
  font-style: italic;
  margin-bottom: 3px;
}

.educationSubDetails {
  float: right;
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 3px;
}

.educationDetails {
  clear: both;
  font-size: 0.6em;
}

.mainDetails {
  border-bottom: 2px solid #cf8a05;
  background: white;
  width: 90%;
  margin: auto;
  padding: 10px 0px 5px;
}

.mainArea {
  margin: auto;
  width: 90%;
}

.keySkills {
  ul li {
    margin-bottom: 3px;
  }
  list-style-type: none;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-align: center;
  color: #444;
}

.quickFade {
  -webkit-animation-name: reset, fade-in;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-timing-function: ease-in;

  -moz-animation-name: reset, fade-in;
  -moz-animation-duration: 2.5s;
  -moz-animation-timing-function: ease-in;

  animation-name: reset, fade-in;
  animation-duration: 2.5s;
  animation-timing-function: ease-in;
}

.delayOne {
  -webkit-animation-delay: 0, 0.5s;
  -moz-animation-delay: 0, 0.5s;
  animation-delay: 0, 0.5s;
}

.delayTwo {
  -webkit-animation-delay: 0, 1s;
  -moz-animation-delay: 0, 1s;
  animation-delay: 0, 1s;
}

.delayThree {
  -webkit-animation-delay: 0, 1.5s;
  -moz-animation-delay: 0, 1.5s;
  animation-delay: 0, 1.5s;
}

.delayFour {
  -webkit-animation-delay: 0, 2s;
  -moz-animation-delay: 0, 2s;
  animation-delay: 0, 2s;
}

.delayFive {
  -webkit-animation-delay: 0, 2.5s;
  -moz-animation-delay: 0, 2.5s;
  animation-delay: 0, 2.5s;
}
